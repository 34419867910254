<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.import')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <!--  Step 1 -->
                <template v-if="step==1">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.import.import_file') }}
                                </template>
                                <b-form-file @change="fileChanged" accept=".csv, .xls, .xlsx, .zip"></b-form-file>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="4">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.import.use_template') }}
                                </template>
                                <b-input-group>
                                    <b-form-checkbox
                                        v-model="form.useTemplate"
                                    ></b-form-checkbox>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="8" v-if="form.useTemplate">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.import.template') }}
                                </template>
                                <b-form-select
                                    :options="templatesOptions"
                                    @input="selectTemplate"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="4">
                            <zw-input-group v-model="form.options.headerLine"
                                            name="import.options.header_line"
                                            validate="required|min_value:1"
                                            type="number"
                                            step="1"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="4">
                            <zw-input-group v-model="form.options.dataStartLine"
                                            name="import.options.data_start_line"
                                            validate="required|min_value:1"
                                            type="number"
                                            step="1"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.import.options.update_existing') }}
                                </template>
                                <b-input-group>
                                    <b-form-checkbox
                                        v-model="form.options.updateExisting"
                                    ></b-form-checkbox>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4"
                               v-if="form.uploadedFile && (getFileExtension(form.uploadedFile.name) == 'csv' || getFileExtension(form.uploadedFile.name) == 'zip')"
                        >
                            <zw-input-group v-model="form.options.delimiter"
                                            name="import.options.delimiter"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <b-button :disabled="!form.uploadedFile" block @click="uploadFile" variant="primary">
                                {{ $t('common.button.upload') }}
                            </b-button>
                        </b-col>
                    </b-row>

                </template>

                <!--  Step 2 -->
                <template v-if="step==2">
                    <b-row>
                        <b-col cols="4">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t('common.label.import.save_template') }}
                                </template>
                                <b-input-group>
                                    <b-form-checkbox
                                        v-model="form.saveTemplate"
                                    ></b-form-checkbox>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" v-if="form.saveTemplate">
                            <zw-input-group v-model="form.templateName"
                                            name="import.template_name"
                                            :placeholder="' '"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-table striped show-empty :items="response.headers" :fields="tableFields">
                        <template #cell(header)="row">
                            <template v-if="row.item"> {{ row.item }}</template>
                            <template v-else>
                                <multiselect
                                    v-model="form.mapping[row.index]"
                                    :options="columnOptions.map(option=>option.value)"
                                    :custom-label="opt => columnOptions.find(option=>option.value==opt).text"
                                    select-label=""
                                />
                            </template>
                        </template>

                        <template #cell(column)="row">
                            <template v-if="!row.item">
                                <template v-if="form.mapping[row.index]=='api_store_id'">
                                    <zw-select-group v-model="form.fixed[row.index]"
                                                     name="api_store_id"
                                                     disable-label
                                                     :options="stores"
                                                     text-field="name"
                                                     value-field="id"
                                    ></zw-select-group>
                                </template>
                                <template v-else-if="form.mapping[row.index]=='api_source_id'">
                                    <zw-select-group v-model="form.fixed[row.index]"
                                                     name="api_source_id"
                                                     disable-label
                                                     :options="getSources()"
                                                     text-field="api_type"
                                                     value-field="id"
                                                     @change="sourceSelected"
                                    ></zw-select-group>
                                </template>
                                <zw-input-group v-else
                                                v-model="form.fixed[row.index]"
                                                disable-label
                                                name="values"/>
                            </template>
                            <multiselect
                                v-else
                                v-model="form.mapping[row.index]"
                                :options="columnOptions.map(option=>option.value)"
                                :custom-label="opt => columnOptions.find(option=>option.value==opt).text"
                                select-label=""
                            />
                        </template>

                        <template #cell(sample)="row">
                            <div style="max-width:200px">
                                {{ response.simpleData[row.index] ? response.simpleData[row.index] : '' }}
                            </div>
                        </template>

                        <template #cell(identifier)="row">
                            <b-form-checkbox
                                v-if="form.mapping[row.index]"
                                v-model="form.identifiers[row.index]"
                                :value="1"
                            ></b-form-checkbox>
                        </template>
                    </b-table>

                    <b-button
                        @click="addRow()"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                    </b-button>

                    <b-row>
                        <b-col cols="12">
                            <b-button block @click="doImport" variant="primary">
                                {{ $t('common.button.import') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </template>

                <!--  Step 3 -->
                <template v-if="step==3">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="card text-white bg-success">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ importResult.created }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('common.label.import.import_created') }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="card text-white bg-info">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="sync"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ importResult.updated }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('common.label.import.import_updated') }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="card text-white bg-warning">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="ban"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ importResult['skipped-rows'].length }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('common.label.import.import_skipped') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-row v-if="importResult['skipped-rows'].length">
                        <b-col cols="12">
                            <b-button block @click="downloadCSVData(importResult['skipped-rows'])"
                                      variant="primary">
                                {{ $t('common.button.download_skipped') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
                <b-row>
                    <b-col cols="3" class="mt-4">
                        <b-button
                            class="mt-4"
                            @click="$root.$children[0].openModal('import-history-modal', {type: payload.type},shown)"
                            variant="primary">
                            {{ $t('common.button.import_history') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import moment from 'moment'
import {mapGetters} from "vuex";

const defaultForm = {
    type: null,
    fileName: null,
    uploadedFile: null,
    mapping: {},
    identifiers: {},
    fixed: {},
    options: {
        headerLine: 1,
        dataStartLine: 2,
        delimiter: ',',
        updateExisting: false,
    },
    useTemplate: false,
    saveTemplate: false,
    templateName: null,
}
export default {
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            response: null,
            templates: [],
            stores: [],
            templatesOptions: [],
            step: 1,
            importResult: null,
            translationGroup: {
                'customer': 'customer.label.',
                'article': 'columns.article.',
                'articlePrices': 'article.label.custom_price.',
                'hotel': 'hotel.column.',
            },
            tableFields: [
                {
                    label: this.$t('common.label.import.header'),
                    key: 'header',
                },
                {
                    label: this.$t('common.label.import.sample'),
                    key: 'sample',
                },
                {
                    label: this.$t('common.label.import.column'),
                    key: 'column',
                },
                {
                    label: this.$t('common.label.import.identifier'),
                    key: 'identifier',
                },
            ],
            form: null
        }
    },
    computed: {
        columnOptions() {
            let options = []
            let self = this

            Object.keys(this.response.columns).forEach(key => {
                let label = this.response.columns[key]

                if (key.startsWith('custom_field.')) {
                    label = 'Custom: ' + label
                } else if (key.startsWith('_attribute.')) {
                    label = 'Attribute: ' + label
                } else if (key.startsWith('_price_list_')) {
                    label = 'Price list: ' + label
                } else if (key.startsWith('cp.')) {
                    label = 'Contact person: ' + self.$t(this.translationGroup[this.payload.type] + label)
                } else {
                    if (this.translationGroup[this.payload.type]) {
                        label = self.$t(this.translationGroup[this.payload.type] + label)
                    }
                }

                options.push({
                    text: label,
                    value: key,
                })
            })

            options.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            });

            return options;
        },
    },
    methods: {
        ...mapGetters('CommonData', ['getSources']),
        ...mapGetters('Multichannel', ['getStores']),
        sourceSelected(id) {
            const stores = this.$store.dispatch('Multichannel/fetchStores', id)
                .then((result) => {
                    result.forEach(store => {
                        this.stores.push({id: store.id, name: store.name});
                    })
                })
        },
        addRow() {
            this.response.headers.push('')
        },
        shown() {
            this.form = JSON.parse(JSON.stringify(defaultForm))
            this.form.type = this.payload.type;
            this.step = 1
            this.response = null
            this.importResult = null

            axios.get(window.apiUrl + '/import/' + this.payload.type, {'skip_loading': true})
                .then(response => {
                    if (response.data) {
                        this.templates = response.data.templates
                        this.templatesOptions = []
                        this.templates.forEach(template => {
                            this.templatesOptions.push({
                                text: template.name + ' ' + moment(template['created_at']).format('d/M/Y H:m'),
                                value: template.id,
                            })
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fileChanged(event) {
            this.form.uploadedFile = event.target.files[0]
        },
        getFileExtension(name) {
            return name.substr(name.lastIndexOf('.') + 1, name.length)
        },
        uploadFile() {
            this.loading = true
            let formData = new FormData();
            formData.append('import_file', this.form.uploadedFile);
            formData.append('type', this.form.type);
            Object.keys(this.form.options).forEach(key => {
                formData.append('options[' + key + ']', this.form.options[key])
            })

            axios.post(window.apiUrl + '/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                'skip_loading': true,
            })
                .then(result => {
                    this.response = result.data
                    this.step = 2

                    this.form.fileName = this.response.fileName
                })
                .finally(() => {
                    this.loading = false
                })
        },
        doImport() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    axios.post(window.apiUrl + '/do-import', this.form, {'skip_loading': true})
                        .then(result => {
                            this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                            this.callback();
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })
        },
        afterImport(response) {
            if (response.status == 200) {
                this.importResult = response.data

                this.step = 3

                if (this.callback) {
                    this.callback()
                }
            }
        },
        downloadCSVData(data) {
            let csv = '';
            data.forEach((row) => {
                csv += row.join(',');
                csv += "\n";
            });

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'skipped.csv';
            anchor.click();
        },
        selectTemplate(val) {
            let template = this.templates.filter(template => {
                return template.id == val
            })[0]

            this.form.options = JSON.parse(JSON.stringify(template.options))
            this.form.mapping = JSON.parse(JSON.stringify(template.mapping))
            this.form.identifiers = JSON.parse(JSON.stringify(template.identifiers))
        }
    }
}
</script>